import { CSSProperties, FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "./TickerCarousel.module.scss";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Box, Tooltip, useMediaQuery } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { backgroundColorState, themeState, tickerTapeState, userState } from "@/lib/store";
import TickertapePopover from "../tickertape-popover/TickertapePopover";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";
import { Instrument } from "@/models/instruments";
import { getTimeDifference, priceDiffPercentage } from "@/lib/utils";
import { Context } from "@/lib/Context";
import { getTopBarValues } from "@/controllers/instruments";
import { getStockInfoOnClient } from "@/controllers/stock";
import { usExchanges } from "@/lib/vars";

interface PropsTypes {
    scrolledDown: boolean;
}

type ParsedDataType = {
    ISIN?: string;
    "ts-last"?: [number, number];
    update?: {
        pch: number;
        nch: number;
    };
    T?: {
        C: {
            o: number;
            h: number;
            l: number;
            c: number;
        };
        i: string;
    };
    LT?: [{ o: number; h: number; l: number; c: number }, number][];
};

type ParsedOrtexDataType = {
    CT?: {
        i: string;
        P: number;
    };
    T?: {
        C: {
            o: number;
            h: number;
            l: number;
            c: number;
        };
        i: string;
    };
};

const liveInstruments: { [identifier: string]: Instrument } = {
    "INDU:IND": { name: "DJIA", currency: "" },
    "SPX:IND": { name: "S&P 500", currency: "" },
    "CCMP:IND": { name: "Nasdaq Comp", currency: "" },
    "US100:IND": { name: "Nasdaq 100", currency: "" },
    "CL1:COM": { name: "OIL (WTI)", currency: "COM" },
    BTCUSD: { name: "Bitcoin" },
    "SX5E:IND": { name: "ESTOXX50", currency: "COM" },
};

const TickerCarousel: FC<PropsTypes> = ({ scrolledDown }) => {
    const { multiWebSocket, ortexWebSocket, obookWebSocket } = useContext(Context);

    const user = useRecoilValue(userState);
    const theme = useRecoilValue(themeState);
    const backgroundColor = useRecoilValue(backgroundColorState);

    const [instrumentsList, setInstrumentsList] = useState([]);

    const [visibility, setVisibility] = useState<string>("hidden");
    const [opacity, setOpacity] = useState<number>(0);
    const [instrumentClicked, setInstrumentClicked] = useState<any>(null);
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [animationPlayState, setAnimationPlayState] = useState<string>("running");
    const [animationDuration, setAnimationDuration] = useState<number>(0);

    const [tickerStocks, setTickerStocks] = useRecoilState(tickerTapeState);
    const [tickerStocksLength, setTickerStocksLength] = useState(0);
    const tickerStocksRef = useRef(tickerStocks);

    const isMobile = useMediaQuery("(max-width: 768px)");

    const tickertapeInitialAnimation = {
        visibility,
        opacity,
        transition: "opacity 0.8s ease-in",
    } as CSSProperties;

    useEffect(() => {
        tickerStocks && setInstrumentsList(Object.entries(tickerStocks).filter((instrument) => instrument[0] !== "undefined"));
    }, [tickerStocks]);

    useEffect(() => {
        setAnimationDuration(instrumentsList.length * 10);
    }, [instrumentsList.length]);

    const handleTickerClick = (e, instrument) => {
        setInstrumentClicked(instrument);
        setAnchorEl(e.currentTarget);
        setAnimationPlayState("paused");
    };

    const handleResumePlaying = () => {
        setInstrumentClicked(null);
        setAnimationPlayState("running");
        setAnchorEl(null);
    };

    useEffect(() => {
        const visibilityTimeout = setTimeout(() => {
            setVisibility("visible");
            setOpacity(1);
        }, 1500);

        return () => clearTimeout(visibilityTimeout);
    }, []);

    const getLiveInstruments = () => {
        getTopBarValues()
            .then((res) => {
                const newInstruments = {};

                Object.keys(res)
                    .filter((key) => key !== "obfuscated" && liveInstruments[key])
                    .forEach((key) => {
                        newInstruments[key] = {
                            name: liveInstruments[key].name,
                            currency: liveInstruments[key].currency,
                            price: res[key].price,
                            lastPrice: res[key].price,
                            lastChange: res[key].nch,
                            timestamp: res[key].ts,
                            visibility: true,
                        };
                    });

                setTickerStocks((prevState) => ({ ...prevState, ...newInstruments }));
            })
            .then(() => {
                getStockInfoOnClient("256505").then((res) => {
                    const btcData = {};

                    btcData["BTCUSD"] = {
                        name: "Bitcoin",
                        price: res.last_close,
                        lastPrice: res.last_close,
                        lastChange: res.last_close - res.previous_close,
                        visibility: true,
                    };

                    setTickerStocks((prevState) => ({ ...prevState, ...btcData }));
                });
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        const savedTickerStocks = user?.settings?.module_settings["tickerTape->tickertape-settings"];

        savedTickerStocks
            ? Object.entries(savedTickerStocks).forEach(([identifier, instrument]: any) => {
                  if (!liveInstruments[identifier] && identifier !== "BTCUSD" && identifier !== "undefined" && identifier !== "WTIUSDBCOMP" && identifier !== "NDX:IND") {
                      getStockInfoOnClient(instrument.stock_id)
                          .then((data) => {
                              setTickerStocks((prevState) => ({
                                  ...prevState,
                                  [identifier]: {
                                      ...instrument,
                                      isin: data.isin,
                                      price: data.last_close,
                                      lastPrice: data.last_close,
                                      lastChange: 0,
                                  },
                              }));
                          })
                          .catch((err) => console.log(err));
                  }
              })
            : getLiveInstruments();
    }, [user?.settings?.module_settings["tickerTape->tickertape-settings"]]);

    useEffect(() => {
        try {
            const prevStocks = tickerStocksRef.current;
            const currentStocks = tickerStocks;

            if (Object.keys(prevStocks).length === Object.keys(currentStocks).length) return;

            // Find removed tickers
            const removedTickers = Object.keys(prevStocks).filter((ticker) => !currentStocks.hasOwnProperty(ticker));

            // Process removed tickers
            removedTickers.forEach((ticker) => {
                const stock = prevStocks[ticker];

                if (!stock || stock.name === "Bitcoin") return;

                const { exchange, isin, currency } = stock;

                if (!exchange) {
                    // This is an index
                    multiWebSocket.send(JSON.stringify({ type: "unsubscribe", identifier: ticker }));
                } else if (usExchanges.includes(exchange)) {
                    // This is a US stock
                    obookWebSocket.send(JSON.stringify({ type: "unsubscribe", identifier: ticker }));
                } else {
                    // This is a non-US stock
                    ortexWebSocket.send(JSON.stringify({ type: "unsubscribe", identifier: `${isin}${currency}` }));
                }
            });

            // Update the ref to the current state
            tickerStocksRef.current = tickerStocks;
        } catch (error) {
            console.log("Error when unsubscribing from WS", error);
        }
    }, [tickerStocks]);

    useEffect(() => {
        if (tickerStocks && Object.entries(tickerStocks).length !== tickerStocksLength) {
            setTickerStocksLength(Object.entries(tickerStocks).length);
        }
    }, [tickerStocks, tickerStocksLength]);

    const sendMultiMessage = useCallback(() => {
        if (Object?.entries(tickerStocks).length > 0) {
            Object?.entries(tickerStocks)
                .filter(([identifier]) => identifier !== "BTCUSD" || !identifier)
                .forEach(([ISIN, { currency, exchange, ticker, isin }]) => {
                    if (exchange) {
                        if (usExchanges.includes(exchange)) {
                            if (obookWebSocket.readyState === 1) obookWebSocket.send(JSON.stringify({ type: "subscribe", identifier: ticker }));
                        } else {
                            // Non US stocks
                            if (ortexWebSocket.readyState === 1) ortexWebSocket.send(JSON.stringify({ type: "subscribe", identifier: isin + currency }));
                        }
                    } else {
                        // Indexes
                        if (multiWebSocket.readyState === 1) multiWebSocket.send(JSON.stringify({ type: "subscribe", ISIN, currency, depth: "false" }));
                    }
                });
        }
    }, [tickerStocksLength]);

    const updateMultiPrices = useCallback(
        ({ data }: MessageEvent) => {
            const parsedData = JSON.parse(data) as ParsedDataType;
            const currentTickerStocks = tickerStocksRef.current;
            if (!currentTickerStocks || !Object.keys(currentTickerStocks).length) return;

            const ISIN = parsedData?.ISIN;
            const tsLast = parsedData?.["ts-last"];
            const update = parsedData?.update;
            const T = parsedData?.T;

            const tickerKey = T?.i || ISIN;

            const stockTicker = Object.entries(currentTickerStocks).find((element) => element[1].ticker === tickerKey)?.[0];
            const tickerStock = currentTickerStocks[tickerKey] || currentTickerStocks[stockTicker];

            if (!tickerStock) return;

            setTickerStocks((prevState) => {
                const currentStock = prevState[tickerKey] || prevState[stockTicker];
                if (!currentStock) return prevState;

                const updatedStock = T
                    ? {
                          ...currentStock,
                          price: T?.C?.c || currentStock.price,
                          lastChange: (T?.C?.c || 0) - (currentStock.lastPrice || 0),
                      }
                    : {
                          ...currentStock,
                          price: tsLast?.[1] || currentStock.price,
                          change: update?.nch || currentStock.change,
                          percentage: update?.pch || currentStock.percentage,
                          timestamp: tsLast?.[0] || currentStock.timestamp,
                      };

                const priceDiff = Math.abs(currentStock.price - updatedStock.price) > 0.01;
                const percentageDiff = Math.abs(currentStock.percentage - updatedStock.percentage) > 0.01;
                const timeDiff = updatedStock.timestamp - currentStock.timestamp > 60 * 1000;

                if (priceDiff || percentageDiff || timeDiff) {
                    return { ...prevState, [prevState[tickerKey] ? tickerKey : stockTicker]: updatedStock };
                }

                return prevState;
            });
        },
        [multiWebSocket, obookWebSocket]
    );

    useEffect(() => {
        if (user.email && multiWebSocket) {
            multiWebSocket.readyState === 1 ? sendMultiMessage() : multiWebSocket.addEventListener("open", sendMultiMessage);
            multiWebSocket.removeEventListener("message", updateMultiPrices);
            multiWebSocket.addEventListener("message", updateMultiPrices);
        }
        if (user.email && obookWebSocket) {
            obookWebSocket.readyState === 1 ? sendMultiMessage() : obookWebSocket.addEventListener("open", sendMultiMessage);
            obookWebSocket.removeEventListener("message", updateMultiPrices);
            obookWebSocket.addEventListener("message", updateMultiPrices);
        }

        return () => {
            if (user.email && multiWebSocket) {
                multiWebSocket.removeEventListener("open", sendMultiMessage);
                multiWebSocket.removeEventListener("open", updateMultiPrices);
            }
            if (user.email && obookWebSocket) {
                obookWebSocket.removeEventListener("open", sendMultiMessage);
                obookWebSocket.removeEventListener("open", updateMultiPrices);
            }
        };
    }, [multiWebSocket, obookWebSocket, user, sendMultiMessage]);

    const sendOrtexMessage = useCallback(() => {
        Object.entries(tickerStocks)
            .filter(([identifier]) => identifier === "BTCUSD" || !identifier)
            .forEach(([identifier]) => {
                if (ortexWebSocket.readyState === 1) {
                    ortexWebSocket.send(JSON.stringify({ type: "subscribe", identifier }));
                }
            });
    }, [tickerStocksLength]);

    const updateOrtexPrices = useCallback(
        ({ data }: MessageEvent) => {
            const parsedData: ParsedOrtexDataType = JSON.parse(data);
            const currentTickerStocks = tickerStocksRef.current;

            if (!currentTickerStocks || !Object.keys(currentTickerStocks).length) return;

            const { CT } = parsedData;
            const T = parsedData.T;

            if (CT?.i in currentTickerStocks && CT?.P) {
                const { i, P } = CT;

                setTickerStocks((prev) => {
                    const instrument = prev[i];
                    const priceDiff = Math.abs(instrument.price - P) > 0.01;

                    if (!instrument || !priceDiff) return prev;

                    return { ...prev, [i]: { ...instrument, price: P, change: P - instrument.lastPrice, percentage: priceDiffPercentage(instrument.lastPrice, P) } };
                });
            }
            if (T) {
                const tickerStock = Object.entries(currentTickerStocks).find((element) => element[1].isin + element[1].currency === T.i);
                const priceDiff = Math.abs(tickerStock[1].price - T.C.c) > 0.01;
                if (!tickerStock || !priceDiff) return;

                setTickerStocks((prevState) => ({ ...prevState, [tickerStock?.[0]]: { ...prevState[tickerStock[0]], price: T.C.c, lastChange: T.C.c - prevState[tickerStock[0]].lastPrice } }));
            }
        },
        [ortexWebSocket]
    );

    useEffect(() => {
        if (user.email && ortexWebSocket) {
            ortexWebSocket.readyState === 1 ? sendOrtexMessage() : ortexWebSocket.addEventListener("open", sendOrtexMessage);
            ortexWebSocket.removeEventListener("message", updateOrtexPrices);
            ortexWebSocket.addEventListener("message", updateOrtexPrices);
        }

        return () => {
            if (user.email && ortexWebSocket) {
                ortexWebSocket.removeEventListener("open", sendOrtexMessage);
                ortexWebSocket.removeEventListener("message", updateOrtexPrices);
                if (ortexWebSocket.readyState === 1) ortexWebSocket.send(JSON.stringify({ type: "unsubscribe", identifier: "BTCUSD" }));
            }
        };
    }, [ortexWebSocket, user, sendOrtexMessage]);

    return (
        <Box
            className={styles.tickerwrap}
            style={tickertapeInitialAnimation}
            sx={{
                boxShadow: isMobile && scrolledDown ? "0 3px 4px rgba(0,0,0,.15)" : "none",
                borderBottom: isMobile && scrolledDown && theme === "dark" ? "1px solid rgb(62, 62, 62)" : "none",
            }}
        >
            {Array(3)
                .fill(0)
                .map((_, i) => {
                    return (
                        <Box className={styles.tickertape} style={{ animationPlayState, animationDuration: `${animationDuration}s` }} key={i}>
                            {instrumentsList
                                .filter((instrument) => instrument[1].visibility && !instrument[1].removed)
                                .map((instrument, i) => (
                                    <Box
                                        className={styles.tickertapeElement}
                                        sx={{
                                            backgroundColor: scrolledDown ? backgroundColor : "transparent",
                                        }}
                                        key={`${instrument[0]}-1`}
                                        onClick={(e) => handleTickerClick(e, instrument)}
                                    >
                                        <Box className={styles.slideItem}>
                                            <p>
                                                {instrument[1]["name"] && `${instrument[1]?.["name"]?.slice(0, instrument[1]?.name?.length > 15 ? 15 : instrument[1]?.name?.length)}${instrument[1]?.name?.length > 15 ? "..." : ""}`}
                                                &nbsp;
                                            </p>
                                            <p>{(instrument[1].price ?? instrument[1].lastPrice)?.toFixed(2)}</p>
                                            <div className={styles.priceVariation}>
                                                {(instrument[1]["change"] ?? instrument[1]["lastChange"]) < 0 ? (
                                                    <ArrowDownwardIcon className={(instrument[1]["change"] ?? instrument[1]["lastChange"]) < 0 ? "negative" : "positive"} sx={{ fontSize: "14px" }} />
                                                ) : (
                                                    <ArrowUpwardIcon className={(instrument[1]["change"] ?? instrument[1]["lastChange"]) < 0 ? "negative" : "positive"} sx={{ fontSize: "14px" }} />
                                                )}
                                                <p className={(instrument[1]["change"] ?? instrument[1]["lastChange"]) < 0 ? "negative" : "positive"}>
                                                    &nbsp;{(instrument[1]["percentage"] ?? (instrument[1]?.["lastChange"] / instrument[1]?.["lastPrice"]) * 100)?.toFixed(2)}%
                                                </p>
                                                {(getTimeDifference(instrument[1].timestamp)[1] < 0 || getTimeDifference(instrument[1].timestamp)[1] > 20 || getTimeDifference(instrument[1].timestamp)[0] !== 0) && (
                                                    <Tooltip arrow title={`Last updated: ${dayjs(instrument[1].timestamp).format("DD MMM HH:mm")}`}>
                                                        <InfoIcon sx={{ fontSize: "14px", marginLeft: 0.5 }} />
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </Box>
                                    </Box>
                                ))}
                        </Box>
                    );
                })}
            <TickertapePopover instrument={instrumentClicked} anchorEl={anchorEl} handleClose={handleResumePlaying} />
        </Box>
    );
};

export default TickerCarousel;
