"use client";

import styles from "./RootLayout.module.scss";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { classNames } from "@/lib/utils";
import Header from "@/components/header/Header";
import { Stock } from "@/models/stock";
import { useRecoilState, useRecoilValue } from "recoil";
import { useRouter } from "next/router";
import { Box, Button, Card, Container, Drawer, IconButton, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { openMenuState, hideTickertapeState, tickerTapeState, userState, textColorState, themeState } from "@/lib/store";
import TickerCarousel from "@/components/ticker-carousel/TickerCarousel";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "@/components/error-fallback/ErrorFallback";
import ContentLayout from "../content/ContentLayout";
import StockHeaderInfo from "@/components/stock-header-info/StockHeaderInfo";
import StockLinks from "@/components/stock-links/StockLinks";
import SettingsIcon from "@mui/icons-material/Settings";
import TickertapeSettings from "@/components/tickertape-settings/TickertapeSettings";

interface PropsTypes {
    stock?: Stock;
}

const RootLayout: FC<PropsTypes> = ({ children, stock }) => {
    const mainRef = useRef(null);
    const isMobile = useMediaQuery("(max-width: 768px)");

    const [user, setUser] = useRecoilState(userState);
    const openMenu = useRecoilValue(openMenuState);
    const textColor = useRecoilValue(textColorState);
    const theme = useRecoilValue(themeState);

    const { pathname, push } = useRouter();

    const [page, setPage] = useState("");
    const [loading, setLoading] = useState<boolean>(true);
    const [scrolledDown, setScrolledDown] = useState(false);
    const [settingsModal, setSettingsModal] = useState(false);

    const [userMenuOpen, setUserMenuOpen] = useState(false);
    const [hideTickertape, setHideTickertape] = useRecoilState(hideTickertapeState);
    const [tickerStocks, setTickerStocks] = useRecoilState(tickerTapeState);
    const userMenuRef = useRef(null);

    const anchorPosition = isMobile ? "bottom" : "right";
    const id = settingsModal ? "simple-popover" : undefined;

    const handleClose = () => {
        setSettingsModal(false);
    };

    useEffect(() => {
        if (pathname.includes("corporate-actions")) setPage("corporate-actions");
        else if (pathname.includes("commodity-exposure")) setPage("commodity-exposure");
        else if (pathname.includes("currency-exposure")) setPage("currency-exposure");
        else if (pathname.includes("trading-signals")) setPage("trading-signals");
        else if (pathname.includes("short-interest")) setPage("short-interest");
        else if (pathname.includes("stock-scores")) setPage("stock-scores");
        else if (pathname.includes("fundamentals")) setPage("fundamentals");
        else if (pathname.includes("analysts")) setPage("analysts");
        else if (pathname.includes("insiders")) setPage("insiders");
        else if (pathname.includes("dividend")) setPage("dividend");
        else if (pathname.includes("holders")) setPage("holders");
        else if (pathname.includes("options")) setPage("options");
        else if (pathname.includes("events")) setPage("events");
        else if (pathname.includes("peers")) setPage("peers");
        else if (pathname.includes("eps")) setPage("eps");
        else if (pathname.includes("stocks-stamps")) setPage("stock-stamps");
        else setPage("overview");
    }, [pathname]);

    useEffect(() => {
        const tickertapeVisibility = user?.settings?.module_settings["tickerTape->tickertape-visibility"];

        if (tickertapeVisibility) {
            setHideTickertape(tickertapeVisibility.isHidden);
        } else {
            setHideTickertape(false);
        }
    }, [user]);

    const handleOutsideMenuClick = useCallback((event) => {
        if (!userMenuRef.current?.contains(event.target)) {
            setUserMenuOpen(false);
            document.removeEventListener("click", handleOutsideMenuClick);
        }
    }, []);

    const logError = (error: Error, info: { componentStack: string; }) => {
        console.error(error, info);
    };

    const handleScroll = () => {
        if (mainRef.current) setScrolledDown(mainRef.current.scrollTop > 0 ? true : false);
    };
    useEffect(() => {
        if (mainRef.current) mainRef.current.addEventListener("scroll", handleScroll);

        return () => {
            if (mainRef.current) mainRef.current.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const goToSettings = () => {
        push("/settings");
        setSettingsModal(false);
    };

    return (
        <div className="ion-app">
            <div className={styles.RootLayout} ref={mainRef}>
                <Box sx={{ display: "flex", width: "100%", position: "fixed", justifyContent: "flex-end" }}>
                    {!hideTickertape && <TickerCarousel scrolledDown={scrolledDown} />}
                    <Box className={styles.tickertapeSettingsBtn}>
                        <Tooltip title="Tickertape Settings" arrow>
                            <IconButton className={styles.button} onClick={() => setSettingsModal(true)} sx={{ p: 0 }}>
                                <SettingsIcon style={{ color: textColor, fontSize: "18px" }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <Header ticker={stock?.ticker} exchange={stock?.exchangesymbol} stock={stock} scrolledDown={scrolledDown} />

                <main className={classNames(styles[user.email && "user"], styles[openMenu === 1 && "open"], styles[openMenu === 0 && "close"], stock && styles.mainStockPage)}>
                    {
                        // @ts-ignore
                        <ErrorBoundary fallbackRender={ErrorFallback} onError={logError}>
                            {stock ? (
                                <ContentLayout
                                    title={
                                        <Box>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Box sx={{ ml: 1 }}>
                                                    <Typography variant="h1">{stock.name}</Typography>
                                                    <Typography variant="body2" style={{ color: textColor }} id={styles["subtitle"]}>
                                                        {`${stock.exchangesymbol}:${stock.ticker} `} | {stock.isin}
                                                    </Typography>
                                                </Box>
                                                {!isMobile && <StockHeaderInfo stock={stock} />}
                                            </Box>
                                            <Box sx={{ height: "48px" }}>
                                                <StockLinks stock={stock} />
                                            </Box>
                                        </Box>
                                    }
                                    stockPage
                                >
                                    <div className={classNames(styles[page], styles["stock-layout"])}>
                                        <div className={styles.content}>
                                            <Container className={styles.muiContainer} maxWidth={false}>
                                                {children}
                                            </Container>
                                        </div>
                                    </div>
                                </ContentLayout>
                            ) : (
                                <Container className={styles.muiContainer} maxWidth={false}>
                                    {children}
                                </Container>
                            )}
                        </ErrorBoundary>
                    }
                </main>

                <Drawer
                    anchor={anchorPosition}
                    id={id}
                    open={settingsModal}
                    onClose={handleClose}
                    PaperProps={{
                        sx: {
                            paddingTop: "env(safe-area-inset-top)",
                            backgroundColor: "transparent",
                            display: "flex",
                            alignItems: "normal",
                            justifyContent: "flex-start",

                            "& .MuiSelect-icon": {
                                color: theme === "dark" && textColor,
                            },
                        },
                    }}
                >
                    <Card className={styles.drawerContent}>
                        <Box>
                            <Typography variant="h2" sx={{ mb: 2 }}>
                                Tickertape Settings
                            </Typography>
                            <TickertapeSettings isModal />
                        </Box>
                        <Box sx={{ alignSelf: "center", marginTop: "auto", display: "flex", justifyContent: "center" }}>
                            <Button onClick={goToSettings} variant="text">
                                All settings
                            </Button>
                        </Box>
                    </Card>
                </Drawer>
            </div>
        </div>
    );
};

export default RootLayout;
