import React, { Dispatch, memo, ReactNode, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { Column, ListQueryParams } from "@/models/table";
import { isEmpty } from "lodash";
import { backgroundColorState, negativeState, positiveState, themeState, userState } from "@/lib/store";
import { Box } from "@mui/material";
import CommentsDisabledIcon from "@mui/icons-material/CommentsDisabled";
import { DataGridPro, GridFilterModel, GridPinnedRowsProp, GridSortDirection, GridSortModel, GridTripleDotsVerticalIcon, useGridApiRef } from "@mui/x-data-grid-pro";
import { Stock } from "@/models/stock";
import TableCell from "@/components/table-cell/TableCell";
import HeaderFilters from "./HeaderFilters";
import { ContentLayoutContext, Context } from "@/lib/Context";
import { DataGridHeaders, FilterState } from "@/models/datagrid";
import { areEqual, hexToRgba, isHex, isOrtexApp, rgbToRgba } from "@/lib/utils";
import { useMedia } from "react-use";
import dayjs from "dayjs";
import { SeriesOptionsType } from "highcharts";
import useOpenSubscriptionForm from "src/hooks/useOpenSubscriptionForm";
import { usExchanges } from "@/lib/vars";

const tableOptions = {
    border: 0,
    width: "100%",
    zIndex: 1,
    "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "600",
        whiteSpace: "normal",
        lineHeight: "normal",
    },
    "& .MuiDataGrid-columnHeaders": {
        // Forced to use important since overriding inline styles
        maxHeight: "max-content !important",
    },
    "& .MuiDataGrid-sortIcon": {
        display: "flex",
        visibility: "visible",
        color: "grey",

        "@media screen and (min-width: 1920px)": {
            display: "flex",
            visibility: "visible",
        },
    },
    "& .MuiDataGrid-cell": {
        fontSize: "11px",

        "@media screen and (min-width: 768px)": {
            fontSize: "12px",
        },
        "@media screen and (min-width: 1280px)": {
            fontSize: "12px",
        },
        "@media screen and (min-width: 1920px)": {
            fontSize: "14px",
        },
        "@media screen and (min-width: 3840px)": {
            fontSize: "18px",
        },

        wordWrap: "break-word !important",
    },
};

const cellHoverStyleOptions = {
    "& .MuiDataGrid-cell:hover": {
        cursor: "pointer",
    },
};

const autosizeOptions = {
    includeOutliers: false,
    includeHeaders: true,
    expand: true,
};

const getFilterableCols = (columns: Column[], columnVisibilityModel = []) => {
    const columnVisibilityModelSet = new Set(Object.keys(columnVisibilityModel));
    const filterableColumns = [];
    const columnsLength = columns.length;

    for (let i = 0; i < columnsLength; i++) {
        if (columns[i].filterable && !columnVisibilityModelSet.has(columns[i]?.field)) {
            filterableColumns.push(columns[i]);
        }
    }

    return filterableColumns;
};

const isSameColumns = (existingHeaders, newColumns) => {
    const sortedExisting = existingHeaders.map((header) => header.field).sort();
    const sortedNew = ["id", ...newColumns].sort();
    return sortedExisting.every((field, i) => field === sortedNew[i]);
};

const NoRowsComponent = ({ isUpcomingEventsModule }) => {
    const boxRef = useRef(null);
    const isHeightLessThan30 = boxRef.current?.offsetHeight < 30;
    const isHeightLessThan100 = boxRef.current?.offsetHeight < 100;
    const fontSize = isHeightLessThan30 ? 20 : isHeightLessThan100 ? 30 : 80;
    const boxFlexDirection = isHeightLessThan100 ? "row" : "column";

    return (
        <Box ref={boxRef} sx={{ mt: 0.5 }} alignItems="center" display="flex" flexDirection={boxFlexDirection} height="100%" alignContent="center" justifyContent="center" width="100%">
            {" "}
            <CommentsDisabledIcon sx={{ fontSize, color: "#dce0e6", mr: 1 }} />
            <Box sx={{ mt: !isHeightLessThan100 && 1 }}>{isUpcomingEventsModule ? "No upcoming events announced" : "No Rows Available"}</Box>
        </Box>
    );
};

const formatCell = (value, { format, hasSubtext }: Column) => {
    const PLAIN_VALUES = ["stock_list", "consensus", "arrow_str"];

    if (PLAIN_VALUES.includes(format) || hasSubtext) return value;
    return [value];
};

const getExtraHiddenCols = (hiddenColumns: string[]) => {
    if (!hiddenColumns) return null;
    return hiddenColumns.reduce((acc, item) => {
        acc[item] = false;
        return acc;
    }, {});
};

let rowsHeightUpdateTimeout = null;
// const perPageRowsQty = "25";

type PropsTypes = {
    applyFiltersToChart?: (filters: any) => void;
    button?: {
        icon?: ReactNode;
        onClick: (filter: number | string, row: object) => void;
        rowKey?: string;
        series?: SeriesOptionsType[];
        tsFlags?: boolean;
    };
    customRowHeight?: number | "auto" | null;
    data?: { values: any; setter: any; defaultValue: any } | Record<any, any>;
    datetime?: boolean;
    expand?: {
        fetcher: (params: ListQueryParams) => Promise<any>;
        field: string;
        text: string;
    };
    expandId?: number;
    expandableRows?: boolean;
    extraButton?: {
        icon?: ReactNode;
        onClick: (filter: number | string, row: object) => void;
        label: string;
        color?: "error" | "inherit" | "primary" | "secondary" | "success" | "info" | "warning";
        tooltip?: string;
    }[];
    extraRow?: any[];
    fetcher: (params: ListQueryParams) => Promise<any>;
    filterColumnType?: any;
    hiddenColumns?: string[];
    id?: number;
    isNewsSearch?: boolean;
    isUpcomingEventsModule?: boolean;
    liveData?: {
        message: (row: (string | number)[]) => any;
        onMessage: (data: any, name: string, row: any, setLivePrice?: (value: Array<number | string>) => void) => number | string | void;
        websocket?: WebSocket;
    }[];
    onRowClick?: (row: any) => any | void;
    onePage?: boolean;
    regionId?: number;
    reset?: { setter: any; defaultValue: any };
    rowsAmount?: number;
    setExportingParams?: (data: any) => void;
    setFilters?: (component: React.ReactNode) => void;
    specifiedColumns?: string[];
    stock?: Stock;
    stock_dates?: boolean;
    tableId?: string;
    updateAvailableColumns?: any;
    updateCurrentColumns?: Dispatch<
        SetStateAction<{
            reset: boolean;
            columns: any[];
        }>
    >;
};

export const DataGrid = ({
    applyFiltersToChart,
    button,
    customRowHeight = "auto",
    data,
    datetime,
    expand,
    expandId,
    expandableRows,
    extraButton,
    extraRow,
    fetcher,
    filterColumnType,
    id,
    isNewsSearch,
    isUpcomingEventsModule,
    liveData,
    onePage,
    regionId,
    reset,
    rowsAmount = 35,
    setExportingParams,
    setFilters,
    specifiedColumns,
    stock,
    stock_dates,
    tableId,
    updateAvailableColumns,
    updateCurrentColumns,
    ...props
}: PropsTypes) => {
    const { openSubscriptionForm } = useOpenSubscriptionForm();
    const { setDisableFilters } = useContext(ContentLayoutContext);
    const { obookWebSocket, ortexWebSocket } = useContext(Context);

    const chooseWebSocket = (exchange, ticker, isin, currency) => {
        if (exchange) {
            if (usExchanges.includes(exchange)) return obookWebSocket;
            else return ortexWebSocket;
        } else if (ticker && !isin && !currency) return obookWebSocket;
        return ortexWebSocket;
    };

    const theme = useRecoilValue(themeState);
    const positive = useRecoilValue(positiveState);
    const negative = useRecoilValue(negativeState);
    const backgroundColor = useRecoilValue(backgroundColorState);
    const user = useRecoilValue(userState);

    const [apiParams, setApiParams] = useState({});
    const [headers, setHeaders] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [customFilters, setCustomFilters] = useState([]);
    const [customFilterModel, setCustomFiltersModel] = useState<GridFilterModel>({ items: [] });
    const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [rows, setRows] = useState([]);
    const [noFilters, setNoFilters] = useState<boolean>(true);
    const [hasMoreRows, setHasMoreRows] = useState<boolean>(true);
    const windowWidth = useRef(typeof window !== "undefined" && window.innerWidth);
    const windowHeight = useRef(typeof window !== "undefined" && window.innerHeight);
    const [filtersCleared, setFiltersCleared] = useState<boolean>(false);
    const [filtersState, setFiltersState] = useState<FilterState>({ previousState: "initial", currentState: "initial" });

    const [clickeableCells, setClickeableCells] = useState(null);
    const [paginationLinks, setPaginationLinks] = useState<any>(false);
    const [resetFilters, setResetFilters] = useState<any>(false);
    const [pinnedCols, setPinnedCols] = useState<{ left: string[]; right: string[] }>({ left: [], right: [] });

    const [pinnedRows, setPinnedRows] = useState<GridPinnedRowsProp>({ top: [], bottom: [] });
    const [chips, setChips] = useState([]);
    const [initialParam, setInitialParam] = useState(false);
    const obfuscatedRef = useRef(false);

    const defaultValues = useRef(null);
    const controller = useRef<AbortController>();
    const page = useRef<number>(1);
    const tableRef = useGridApiRef();
    const isMounted = useRef<boolean>(false);
    const totalPages = useRef<number>(1);
    const hiddenColumns = useRef<Record<string, any>>({});
    const currentColumns = useRef<string[]>([]);

    const isMobile = useMedia("(max-width: 768px)");
    const isLaptop = useMedia("(max-width: 1280px)");

    const initialRowsQty = windowWidth.current <= 1920 && windowHeight.current <= 1720 ? rowsAmount : rowsAmount * 2;

    useEffect(() => {
        try {
            const callbacks = {};

            const sendWebSocketMessages = (obj, rows) => {
                rows.forEach((row) => {
                    const messages = obj.message(row);
                    const ws = obj.websocket || chooseWebSocket(row.exchangeSymbol || row.exchangesymbol, row.ticker, row.isin, row.currency);

                    if (ws.readyState === 1) {
                        messages.forEach((message) => {
                            if (message?.ISIN || message?.identifier) {
                                ws.send(JSON.stringify(message));
                            }
                        });
                    } else {
                        ws.addEventListener("open", () => {
                            messages.forEach((message) => {
                                if (message?.ISIN || message?.identifier) {
                                    ws.send(JSON.stringify(message));
                                }
                            });
                        });
                    }
                });
            };

            const unsubscribeWebSocketMessages = (obj, rows) => {
                rows.forEach((row) => {
                    const messages = obj.message(row);
                    messages.forEach((message) => {
                        if (message?.ISIN || message?.identifier) {
                            (obj.websocket || chooseWebSocket(row.exchangeSymbol || row.exchangesymbol, row.ticker, row.isin, row.currency)).send(JSON.stringify({ type: "unsubscribe", ...message }));
                        }
                    });
                });
            };

            if (liveData) {
                liveData.forEach((obj, i) => {
                    if (rows.length) {
                        callbacks[i] = () => {
                            sendWebSocketMessages(obj, rows);
                        };

                        if (obj.websocket) {
                            if (obj.websocket.readyState === 1) {
                                callbacks[i]();
                            } else {
                                obj.websocket.addEventListener("open", callbacks[i]);
                            }
                        } else {
                            if (obookWebSocket.readyState !== 1) {
                                obookWebSocket.addEventListener("open", callbacks[i]);
                            } else {
                                callbacks[i](); // WebSocket is already open
                            }

                            if (ortexWebSocket.readyState !== 1) {
                                ortexWebSocket.addEventListener("open", callbacks[i]);
                            } else {
                                callbacks[i](); // WebSocket is already open
                            }
                        }
                    }
                });
            }

            return () => {
                if (liveData) {
                    liveData.forEach((obj, i) => {
                        if (rows.length) {
                            if (obj.websocket) {
                                obj.websocket.removeEventListener("open", callbacks[i]);

                                if (obj.websocket.readyState === 1) {
                                    unsubscribeWebSocketMessages(obj, rows);
                                }
                            } else {
                                obookWebSocket.removeEventListener("open", callbacks[i]);
                                ortexWebSocket.removeEventListener("open", callbacks[i]);

                                if (obookWebSocket.readyState === 1 && ortexWebSocket.readyState === 1) {
                                    unsubscribeWebSocketMessages(obj, rows);
                                }
                            }
                        }
                    });
                }
            };
        } catch (error) {
            console.log("Error with WS ", error);
        }
    }, [...(liveData || []).map((obj) => obj.websocket), rows]);

    useEffect(() => {
        if (!hiddenColumns.current?.mobile || !hiddenColumns.current?.laptop) return;
        const extraHiddenCols = getExtraHiddenCols(props.hiddenColumns);

        if (isMobile || isOrtexApp()) {
            setColumnVisibilityModel({ ...hiddenColumns.current.mobile, ...extraHiddenCols });
        } else if (isLaptop) {
            setColumnVisibilityModel({ ...hiddenColumns.current.laptop, ...extraHiddenCols });
        }
    }, [rows, isMobile, isLaptop]); // Don't change dependencies

    const pinnedList = useRef({ left: [], right: [] });

    const createColumn = (header: Column, obfuscated?: boolean) => {
        const { field, flex, hasTooltip, headerAlign, headerName, sortable, filterable, pinDirection } = header;

        if (pinDirection && !pinnedList.current[pinDirection].includes(field)) pinnedList.current[pinDirection].push(field);
        if (filterable && noFilters) setNoFilters(false);
        const column: DataGridHeaders = {
            ...(pinDirection && { pinnable: false }),
            headerAlign: headerAlign || "left",
            field,
            headerName,
            sortable: obfuscated ? !obfuscated : sortable,
            filterable,
            flex: isMobile || isOrtexApp() ? null : flex || 1,
            minWidth: isMobile || isOrtexApp() || flex === 2 ? 120 : flex >= 5 ? 250 : 120,
            disableColumnMenu: !headerName,
            renderCell: (params) => (
                <TableCell
                    button={button}
                    extraButton={extraButton && extraButton.find((item) => (item.label === "Reset Alert" && headerName === "Status") || item.label === headerName)}
                    datetime={datetime}
                    header={header}
                    liveData={liveData}
                    onRowClick={!obfuscatedRef.current && props.onRowClick}
                    row={params.row}
                    value={hasTooltip ? [params.value[0]] : formatCell(params.value, header)}
                    tooltip={params.value?.[1]}
                    className={obfuscated ? "obfuscated" : undefined}
                    stock_dates={stock_dates || false}
                    key={header?.field + params.row?.id}
                />
            ),
            getApplyQuickFilterFn: null,
        };

        return column;
    };

    const handlePinnedColsChange = (newPinnedCols) => {
        pinnedList.current = newPinnedCols;
        setPinnedCols(newPinnedCols);
    };

    let isCallSuccessful = false;

    const formatHeaders = (headers, obfuscated = false) => {
        if (!Array.isArray(headers)) {
            console.error("headers is not an array:", headers);
            return [];
        }

        const result = [];
        headers.sort((a, b) => a.order - b.order);

        for (const header of headers) result.push(createColumn(header, obfuscated));

        return result;
    };

    const fetchData = (page: number = 1, paginationLink?, resetValues?) => {
        controller.current?.abort();
        controller.current = new AbortController();
        setLoading(true);
        const formattedSortModel = sortModel?.length &&
            apiParams !== undefined &&
            !apiParams.hasOwnProperty("sortModel") && {
                sortModel: sortModel.reduce((result, { field, sort }) => {
                    result[field] = sort;
                    return result;
                }, {}),
            };

        let newApiParams = !isMounted.current ? { ...apiParams, initial: true } : { ...apiParams, ...formattedSortModel };
        if (!isMounted.current) defaultValues.current = { newApiParams, data: data };
        !isMounted.current && setFiltersState((prev) => ({ previousState: prev.currentState, currentState: "normal" }));

        const savedFilters = tableId && user.settings?.module_settings?.["datagrid->saved-filters"]?.[tableId];
        if (!isMounted.current && savedFilters) newApiParams = { ...apiParams, ...user.settings?.module_settings?.["datagrid->saved-filters"]?.[tableId] };

        setInitialParam(resetValues ? resetValues.newApiParams?.["initial"] === true : newApiParams?.["initial"] === true);
        fetcher({
            page_size: initialRowsQty,
            page,
            filter_options: resetValues ? resetValues.newApiParams : newApiParams,
            universe_id: regionId ? regionId : stock ? stock.stock_id : id,
            data: resetValues ? resetValues.data : data || {},
            expand_id: expandId,
            signal: controller.current.signal,
            next_page_url: paginationLink,
        })
            .then((res) => {
                if (res.message === "canceled") {
                    return (isCallSuccessful = false);
                }

                isCallSuccessful = true;

                if (isEmpty(headers) || page === 1) {
                    setClickeableCells(res?.columns?.some((header) => !header.onClick));
                    if (extraButton) {
                        extraButton.forEach((button) => {
                            if (button.label === "Delete Alert") {
                                res.columns.push({ class: "", field: "delete alert", format: "extra-button", headerName: button.label, on_click: "delete_alert", on_click_argument: "id", order: 6, sortable: false });
                            } else if (button.label === "Edit Alert") {
                                res.columns.push({ class: "", field: "edit alert", format: "extra-button", headerName: button.label, on_click: "edit_alert", on_click_argument: "id", order: 5, sortable: false });
                            } else if (button.label === "Reset Alert") {
                                return;
                            } else {
                                res.columns.push({ class: "la la-line-chart", field: "show holders", format: "extra-button", headerName: button.label, on_click: "show_holders", on_click_argument: "company_id", order: 6, sortable: false });
                            }
                        });
                    }

                    if (!headers.length || (res?.availableColumns && !isSameColumns(headers, res.columns)) || columnVisibilityModel !== res.columnVisibilityModel) {
                        const columns = formatHeaders(res.columns, res.obfuscated);
                        setDisableFilters(!!res.obfuscated);
                        obfuscatedRef.current = !!res.obfuscated;
                        setHeaders(columns);

                        setCustomFilters(getFilterableCols(res.columns, res?.columnVisibilityModel));
                    }

                    setPinnedCols(() => pinnedList.current);

                    hiddenColumns.current = { ...res.columnVisibilityModel, ...getExtraHiddenCols(props.hiddenColumns) };
                    const visibleColumns = res.columnVisibilityModel && res.columnVisibilityModel.other ? res.columnVisibilityModel.other : res.columnVisibilityModel || {};
                    setColumnVisibilityModel({ ...visibleColumns, ...getExtraHiddenCols(props.hiddenColumns) });

                    if (res?.availableColumns) {
                        // Export available columns list
                        if (!isMounted.current) updateAvailableColumns && updateAvailableColumns(res?.availableColumns);

                        // Extract current columns from first row.
                        currentColumns.current = res.columns.reduce((acc: string[], column: Column) => {
                            if (column.field !== "id") {
                                acc.push(column.field);
                            }
                            return acc;
                        }, []);
                        // Export current columns list
                        if (updateCurrentColumns) {
                            updateCurrentColumns((prevState) => ({
                                ...prevState,
                                columns: currentColumns.current,
                                reset: resetValues && Object.keys(resetValues).length ? true : prevState.reset,
                            }));
                        }

                        // This may replace line 439.
                        setExportingParams && setExportingParams((prevState) => ({ ...prevState, columns: currentColumns.current }));
                    }

                    totalPages.current = Math.ceil(res.length / initialRowsQty);
                    isMounted.current = true;
                    if (setExportingParams && !res.availableColumns) {
                        const exportColumns = Object.entries(res.columns as Column[]).reduce((acc, column) => {
                            if (column[1].display !== false && column[1].field !== "id") {
                                acc.push(column[1].field);
                            } else if (column[1].field === "ticker") acc.unshift(column[1].field);
                            return acc;
                        }, []);
                        setExportingParams((prevState) => ({ ...prevState, columns: exportColumns }));
                    }
                }

                setPaginationLinks(res.paginationLinks);

                if (setExportingParams) {
                    setExportingParams((prevState) => ({ ...prevState, filters: { ...prevState["filters"], sort: res.sortModel } }));
                }
                ///////////////////
                // if (specifiedColumns) {
                //     const notVisibleColumns = res.columns
                //         .filter((column) => !props?.specifiedColumns.includes(column.field))
                //         .reduce((acc, curr) => {
                //             return { ...acc, [curr.field]: false };
                //         }, {});
                //     setColumnVisibilityModel(notVisibleColumns);
                //     setExportingParams((prevState) => ({ ...prevState, columns: specifiedColumns }));
                // }
                ////////////////////
                res.sortModel ? setSortModel(Object.entries(res.sortModel).map(([field, sort]: [string, GridSortDirection]) => ({ field, sort }))) : setSortModel([]);

                res.customFilterModel ? setCustomFiltersModel(res.customFilterModel) : setCustomFiltersModel({ items: [] });
                res.customFilterModel && applyFiltersToChart && applyFiltersToChart({ items: res.customFilterModel.items, page: page });

                res.filterModel ? setFilterModel(res.filterModel) : setFilterModel({ items: [] });

                setPinnedRows({ top: res.rows?.filter((row) => row.rowPinning), bottom: [] });

                if (!res.rows?.length && !(page > res.length)) return setRows([]);
                setRows((prevRows) => (page > 1 ? prevRows.concat(res.rows ?? []) : res.rows ?? []));

                if (res.paginationLinks) setHasMoreRows(page < totalPages.current);
                else setHasMoreRows(page - 1 + initialRowsQty < res.length);
            })
            .finally(() => {
                if (isCallSuccessful) setLoading(false);
                if (defaultValues.current) {
                    setTimeout(() => {
                        setResetFilters(false);
                    }, 200);
                }
                if (filtersState.currentState !== "normal") setFiltersState((prev) => ({ previousState: prev.currentState, currentState: "normal" }));
                setFiltersCleared((prev) => false);
            });
    };

    const loadMoreData = () => {
        if (onePage || loading || obfuscatedRef.current) return;

        if (isMounted.current && paginationLinks?.next) {
            const next = paginationLinks.next.split("page=")[1].split("&")[0];
            const url = new URL(paginationLinks.next);
            // url.searchParams.set("page_size", perPageRowsQty);
            url.searchParams.delete("authid");
            url.searchParams.delete("GUIv");
            fetchData(next, url.toString());
        }
    };

    const onRowClick = () => {
        if (obfuscatedRef.current) openSubscriptionForm();
    };
    useEffect(() => {
        if (setFilters && customFilters.length > 0) {
            setFilters((prevState) => {
                return (
                    <>
                        {/* if the prevState children isnt an array, then is the initial one defined on the parent component */}
                        {!Array.isArray(prevState?.props?.children) ? prevState : prevState.props.children[0]}
                        <HeaderFilters
                            filters={currentColumns.current.length ? customFilters.filter((filt) => currentColumns.current.includes(filt.field)) : customFilters}
                            setParams={setApiParams}
                            setCustomFiltersModel={setCustomFiltersModel}
                            values={resetFilters ? [...filterModel.items] : [...customFilterModel.items, ...filterModel.items]}
                            fetcher={fetchData}
                            defaultValues={defaultValues.current}
                            setResetFilters={setResetFilters}
                            resetFilters={resetFilters}
                            data={data}
                            reset={reset}
                            chips={chips}
                            setChips={setChips}
                            isNewsSearch={isNewsSearch}
                            stockColumns={filterColumnType || headers.filter((item) => ["stock_id", "tradingitem_id", "security_id", "company_id", "id"].includes(item.field))}
                            initialParam={initialParam}
                            tableId={tableId}
                            filtersCleared={filtersCleared}
                            setFiltersCleared={setFiltersCleared}
                            filtersState={filtersState}
                            setFiltersState={setFiltersState}
                        />
                    </>
                );
            });
        }

        if (setExportingParams && customFilterModel.items.length > 0) {
            const formattedSortModel = sortModel.reduce((result, { field, sort }) => {
                result[field] = sort;
                return result;
            }, {});
            setExportingParams((prevState) => ({ ...prevState, customFilterModel, sortModel: formattedSortModel }));
        }
    }, [setFilters, customFilters, customFilterModel, filterModel, specifiedColumns, resetFilters, chips]);

    useEffect(() => {
        if (!resetFilters) fetchData();
    }, [data, id, stock, specifiedColumns, regionId]);

    useEffect(() => {
        if (!isMounted.current) return;
        else if (!resetFilters) {
            tableRef.current.scrollToIndexes({ rowIndex: 0, colIndex: 0 });
            page.current = 1;
            fetchData();
        }
    }, [apiParams]);

    const onSortModelChange = (newSortModel: GridSortModel) => {
        const hasSortModelChanged = newSortModel?.length !== sortModel?.length || newSortModel.some((item) => !sortModel.includes(item));

        if (hasSortModelChanged) {
            const newModel = newSortModel.reduce((result, { field, sort }) => {
                result[field] = sort;
                return result;
            }, {});

            setSortModel(newSortModel);
            setApiParams({ ...apiParams, sortModel: newModel, customFilterModel });
        }
    };

    const onFilterModelChange = (newFilterModel: GridFilterModel) => {
        !areEqual(newFilterModel, filterModel) && setFilterModel(newFilterModel);
    };

    const updateRowsHeight = () => tableRef.current.resetRowHeights();

    const debouncedUpdateRowsHeight = () => {
        clearTimeout(rowsHeightUpdateTimeout);
        rowsHeightUpdateTimeout = setTimeout(updateRowsHeight, 300);
    };

    const handleWindowResize = () => {
        windowWidth.current = window.innerWidth;
        debouncedUpdateRowsHeight();

        tableRef.current.autosizeColumns(autosizeOptions);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
            setClickeableCells(null);
            clearTimeout(rowsHeightUpdateTimeout);
        };
    }, []);

    useEffect(() => {
        if (tableRef.current && rows.length <= initialRowsQty) {
            tableRef.current.autosizeColumns(autosizeOptions);
        }
    }, [rows]);

    useEffect(() => {
        if (extraRow && rows.length) {
            const rowWithID = extraRow.map((row) => ({
                ...row,
                id: row.id + rows.length,
            }));
            setRows((prevRows) => [...rowWithID, ...prevRows]);
        }
    }, [extraRow]);

    useEffect(() => {
        return () => {
            if (button !== undefined && button.tsFlags) {
                button.series = [];
            }
        };
    }, [id]);

    return (
        <>
            <style>
                {`
            .MuiDataGrid-row .StackedBar_positive {
                background-color: ${isHex(positive) ? hexToRgba(positive, 0.6) : rgbToRgba(positive, 0.6)};
            }        
            .MuiDataGrid-row:hover .StackedBar_positive {
                background-color: ${positive};
            }
            .MuiDataGrid-row .StackedBar_negative {
                background-color: ${isHex(negative) ? hexToRgba(negative, 0.6) : rgbToRgba(negative, 0.6)};
            }
            .MuiDataGrid-row:hover .StackedBar_negative {
                background-color: ${negative};
            }

            .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
            .MuiDataGrid-root .MuiDataGrid-cell:focus {
                outline: none !important;
            }

            .MuiDataGrid-pinnedColumns {
                background-color: ${backgroundColor};

            }

  
            
            `}
            </style>
            {
                <DataGridPro
                    disableRowSelectionOnClick
                    disableColumnFilter
                    hideFooter
                    apiRef={tableRef}
                    columns={headers}
                    columnVisibilityModel={columnVisibilityModel}
                    getRowId={(row) => `${row.indexName}-${Math.random()}`}
                    slots={{
                        noRowsOverlay: () => <NoRowsComponent isUpcomingEventsModule={isUpcomingEventsModule} />,
                        columnMenuIcon: () => <GridTripleDotsVerticalIcon style={{ color: theme === "dark" && "#fff" }} />,
                    }}
                    disableColumnSelector={true}
                    getRowHeight={() => customRowHeight}
                    filterMode="server"
                    filterModel={{ items: [...filterModel.items, ...customFilterModel.items] }}
                    loading={loading}
                    onFilterModelChange={onFilterModelChange}
                    onRowClick={!clickeableCells ? onRowClick : null}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    initialState={{
                        sorting: { sortModel: sortModel },
                    }}
                    sortModel={sortModel}
                    rows={rows}
                    pinnedRows={pinnedRows}
                    density="standard"
                    sx={{
                        ...tableOptions,
                        ...(props.onRowClick && cellHoverStyleOptions),
                        "& .MuiDataGrid-columnHeaders, .MuiDataGrid-cell": {
                            borderBottom: `1px solid ${theme === "dark" ? "rgb(81, 81, 81)" : "rgb(224, 224, 224)"}`,
                            paddingBottom: "2px",
                            paddingTop: "2px",
                        },
                    }}
                    onRowsScrollEnd={loadMoreData}
                    pinnedColumns={pinnedCols}
                    onPinnedColumnsChange={handlePinnedColsChange}
                    {...(isMobile ? { onColumnResize: debouncedUpdateRowsHeight } : { onColumnWidthChange: updateRowsHeight })}
                    autosizeOnMount={isMobile || isOrtexApp()}
                    autosizeOptions={autosizeOptions}
                />
            }
        </>
    );
};

export const MemoizedDataGrid = memo(DataGrid, areEqual);
